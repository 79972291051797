import { ROUTES } from "../const";

function populateStage(stage: number | string): number {
  let result = stage;
  if (result === undefined || result === null) {
    const pathname = window.location.pathname;
    if (pathname.includes("foundation")) {
      result = 1;
    } else if (pathname.includes("advanced")) {
      result = 2;
    }
  }
  if (typeof result === "string") {
    result = parseInt(result);
  }

  if (result > 2) {
    throw new Error("populateRoutes: this stage is unimplemented! :: " + result + " :: " + stage);
  }

  return result;
}

export function getStageRoute(stage?: number | string) {
  const stageNumber = populateStage(stage);
  let route = undefined;
  if (stageNumber === 1) {
    route = ROUTES.side.courses.foundation(stage);
  } else if (stageNumber === 2) {
    route = ROUTES.side.courses.advanced(stage);
  }

  if (!route) {
    console.error("getStageRoute: this module hasn't been implemented yet!");
    return;
  }
  return route;
}

export function getModuleRoute(module: string | number, stage?: number | string) {
  const stageNumber = populateStage(stage);
  let route = undefined;
  if (stageNumber === 1) {
    route = ROUTES.side.courses.foundation_module(module);
  } else if (stageNumber === 2) {
    route = ROUTES.side.courses.advanced_module(module);
  }

  if (!route) {
    console.error("getModuleRoute: this module hasn't been implemented yet! :: ", stageNumber);
    return;
  }
  return route;
}

export function getSubModuleRoute(submodule: string | number, stage?: string | number) {
  const stageNumber = populateStage(stage);
  let route = undefined;
  if (stageNumber === 1) {
    route = ROUTES.side.courses.foundation_submodule(submodule);
  } else if (stageNumber === 2) {
    route = ROUTES.side.courses.advanced_submodule(submodule);
  }

  if (!route) {
    console.error("getSubModuleRoute: this submodule hasn't been implemented yet!");
    return;
  }
  return route;
}

export function getMaterialRoute(material: string | number) {
  const stageNumber = populateStage(undefined);
  let route = undefined;
  if (stageNumber === 1) {
    route = ROUTES.side.courses.foundation_material(material);
  } else if (stageNumber === 2) {
    route = ROUTES.side.courses.advanced_material(material);
  }

  if (!route) {
    console.error("getMaterialRoute: this material hasn't been implemented yet!");
    return;
  }
  return route;
}
